<template>
    <div>
        <NavBar
                title="医生信息注册"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <vfrom @submit="onSubmit"   >
            <vfield
                    v-model="user.name"
                    name="name"
                    label="姓名"
                    required
                    placeholder="请输入姓名"
                    :rules="[{ required: true, message: '姓名不能为空' }]"
            />
            <vfield
                    v-model="user.username"
                    name="username"
                    required
                    label="用户名"
                    placeholder="请输入用户名"
                    :rules="[{ required: true, message: '用户名不能为空' }]"
            />
            <vfield name="gender" label="性别" required >
                <template #input >
                    <RadioGroup v-model="user.sex" direction="horizontal">
                        <Radio name="男" >男</Radio>
                        <Radio name="女" >女</Radio>
                    </RadioGroup>
                </template>
            </vfield>
<!--            <vfield-->
<!--                    name="centerId"-->
<!--                    :value="user.centerId"-->
<!--                    v-show="false"-->
<!--            />-->
            <vfield
                    readonly
                    clickable
                    name="centerName"
                    :value="centerName"
                    placeholder="请选择"
                    label="科室"
                    required
                    @click="showPicker = true"
            />
            <Popup v-model="showPicker" position="bottom">
                <Picker
                        show-toolbar
                        :columns="opts"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
                />
            </Popup>
            <vfield
                    v-model="user.title"
                    name="zhicheng"
                    label="职称"
                    placeholder="请输入职称"
            />
            <vfield
                    v-model="user.remark"
                    name="remark"
                    label="备注"
                    placeholder="请输入备注"
            />
            <vfield
                    v-model="user.phone"
                    name="phone"
                    required
                    label="手机号"
                    placeholder="请输入手机号"
                    :rules="[{ required: true, message: '手机号不能为空' }]"
            />
            <vfield v-model="code" name="code"
                    center
                    required
                    clearable
                    label="验证码"
                    placeholder="请输入验证码"
                    :border="false"
                    use-button-slot
                    :rules="[{ validator: valiVerifyCode, message: '验证出错'}]"
            >
                <Button slot="button"
                        size="small"
                        type="info" native-type="button"
                        @click="gainCode">{{time}}</Button>
            </vfield>
            <vfield name="uploader" label="图片上传">
                <template #input>
                    <vuploader v-model="files" :max-count="1"  :after-read="afterRead" />
                </template>
            </vfield>
            <div style="margin-left: 20px;margin-top: 10px;font-size:14px">
                <vcheckbox v-model="isagree" icon-size="12" shape="square" @change="changeAgree">
                    我已阅读并同意
<!--                    <a @click="showFWXY" style="color: #007aff">服务协议</a>、-->
                    <a @click="showYSZC" style="color: #007aff">《关节通隐私政策及协议》</a>
                </vcheckbox>

            </div>
            <div style="margin: 16px;">
                <Button round block type="info" :disabled="substatus||agreeStatus" native-type="submit" >提交</Button>
            </div>
        </vfrom>
        <ActionSheet v-model="showYszc" title="关节通隐私政策及协议">
            <div class="content" style="margin-left: 2em;margin-right: 2em;text-indent:2em;font-size:13px">
                <p></p>
                <p>1.适用范围</p>
                <p>1.1本政策仅适用于关节通微信公众号服务（以下简称“关节通”）的运营主体及客户端服务可能存在的运营关联单位，具体指深圳市瑞林泰克科技有限公司（以下简称“瑞林泰克”）。使用前述关节通服务的使用人在本政策中称为“用户”，或称为“您”。 </p>
                <p>1.2请您在使用微信公众号服务前，仔细阅读并充分理解本隐私保护政策注册协议。您在点击“确认”按钮后，本政策即构成对双方有约束力的法律文件，即表示您同意瑞林泰克按照本政策收集、使用、处理和存储您的相关信息。如果您对本隐私政策有任何疑问、意见或建议，可通过本政策第5条提供的联系方式与我们联系。 </p>
                <p>1.3瑞林泰克深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，并恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，瑞林泰克承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</p>
                <p></p>
                <p>2.隐私保护政策具体内容</p>
                <p>2.1 我们如何收集和使用信息 </p>
                <p>我们会按照如下方式收集您在使用服务时主动提供的、以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息： </p>
                <p>2.1.1注册问卷： </p>
                <p>当您关注公众号提交注册问卷时，注册问卷中个人信息和其他有无关节不适信息。 </p>
                <p>2.1.2沟通功能 </p>
                <p>医患沟通中咨询的运动训练及康复情况等交流信息。 </p>
                <p>2.1.3定向推送 </p>
                <p>我们会基于收集的信息，推送相关的科普图文、运动康复训练视频、量表。为此，我们需要收集的信息包括您的量表内容。 </p>
                <p>2.1.4安全运行 </p>
                <p>安全保障功能我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务是我们的核心目标。读、写入外部存储，唯一标识符为实现安全运行所收集的必要权限和信息。 </p>
                <p></p>
                <p>2.2.我们如何共享、转让、公开披露您的个人信息 </p>
                <p>2.2.1共享 </p>
                <p>我们不会向瑞林泰克以外的任何公司、组织和个人分享您的个人信息，但以下情况除外： </p>
                <p>2.2.1.1在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。 </p>
                <p>2.2.1.2我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。  </p>
                <p>2.2.1.3与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息及问卷信息，以提供更好的客户服务和用户体验。例如，根据个人信息和问卷信息情况医师更有针对性咨询和推送相关视频及问卷。 </p>
                <p>目前，我们的授权合作伙伴包括各地区医疗服务类的授权合作伙伴。  </p>
                <p>2.2.2转让 </p>
                <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外： </p>
                <p>2.2.2.1在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息； </p>
                <p>2.2.2.2在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。 </p>
                <p>2.2.3公开披露 </p>
                <p>我们仅会在以下情况下，公开披露您的个人信息： </p>
                <p>2.2.3.1获得您明确同意后； </p>
                <p>2.2.3.2基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，主要包括以下情景： </p>
                <p>a.与国家安全、国防安全直接相关的； </p>
                <p>b.与公共安全、公共卫生、重大公共利益直接相关的； </p>
                <p>c.与犯罪侦查、起诉、审判和判决执行等直接相关的； </p>
                <p>d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； </p>
                <p>e.所收集的您的个人信息是您自行向社会公众公开的； </p>
                <p>f.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道； </p>
                <p>g.根据您的要求签订或履行合同所必需的； </p>
                <p>h.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障； </p>
                <p>i.为合法的新闻报道所必需的； </p>
                <p>j.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； </p>
                <p>k.法律法规规定的其他情形。 </p>
                <p>特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
                <p></p>
                <p>2.3.我们如何存储和保护信息 </p>
                <p>2.3.1 存储地点 </p>
                <p>我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将遵循相关国家规定或者征求您的同意。 </p>
                <p>2.3.2存储期限 </p>
                <p>我们仅在为提供关节通及服务之目的所必须的期间内保存您的个人信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。 </p>
                <p>2.3.3 技术措施与数据保护措施 </p>
                <p>我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。 </p>
                <p>2.3.4 安全事件处理 </p>
                <p>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                <p></p>
                <p>3.您的权利 </p>
                <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利： </p>
                <p>3.1访问您的个人信息 </p>
                <p>如果您希望访问或编辑 您的账户中个人账户及注册问卷信息，您可以通过访问个人中心—>我的信息进行查看。   </p>
                <p>3.2访问您的健康记录 </p>
                <p>如果您希望访问或编辑 您的每日健康记录，您可以通过访问个人中心—>健康记录进行查看。</p>
                <p>3.3访问您的收到的手术康复视频 </p>
                <p>如果您希望访问收到的手术康复视频，您可以通过访问个人中心—>康复方案进行查看。 </p>
                <p>3.4访问您的收到的非手术运动视频 </p>
                <p>如果您希望访问您的收到的非手术运动视频，您可以通过访问个人中心—>非术后视频。 </p>
                <p>3.5约束信息系统自动决策 </p>
                <p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释式。 </p>
                <p>3.6响应您的上述请求 </p>
                <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在十五天内做出答复。 </p>
                <p>在以下情形中，我们将无法响应您的请求： </p>
                <p>a、与个人信息控制者履行法律法规规定的义务相关的； </p>
                <p>b、与国家安全、国防安全直接相关的； </p>
                <p>c、与公共安全、公共卫生、重大公共利益直接相关的； </p>
                <p>d、与犯罪侦查、起诉、审判和执行判决等直接相关的； </p>
                <p>e、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的； </p>
                <p>f、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； </p>
                <p>g、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的； </p>
                <p>h、涉及商业秘密的。</p>
                <p></p>
                <p>4.本政策的适用及更新 </p>
                <p>我们可能会不时对《关节通隐私政策及协议》进行修订，当《关节通隐私政策及协议》发生重大变更修订时，我们会在版本更新后以推送通知或弹窗等形式向您展示变更后的内容。请您注意，只有在您确认修订后的《关节通隐私政策》后，我们才会按照修订后的《关节通隐私政策及协议》收集、使用、处理和存储您的个人信息；您可以选择不同意修订后的《关节通隐私政策及协议》，但可能导致您无法使用关节通的部分服务及功能。 </p>
                <p>未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。 </p>
                <p>本政策所指的重大变更包括但不限于： </p>
                <p>a、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等； </p>
                <p>b、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等； </p>
                <p>c、个人信息共享、转让或公开披露的主要对象发生变化； </p>
                <p>d、您参与个人信息处理方面的权利及其行使方式发生重大变化； </p>
                <p>e、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时； </p>
                <p>f、个人信息安全影响评估报告表明存在高风险时。 </p>
                <p></p>
                <p>5.如何联系我们 </p>
                <p>我们已经设立了个人信息保护负责人员，如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系： </p>
                <p>电话：4000-365-880 </p>
                <p>如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。 </p>
                <br>
                <br>
            </div>
        </ActionSheet>

        <RLFooter/>
    </div>

</template>

<script>
    import {  Form as vfrom,Field as vfield,Button,Radio,RadioGroup,Popup ,Picker,Uploader as vuploader ,ActionSheet,Checkbox as vcheckbox} from 'vant';
    // import {getBaseInfo} from '@/api/patient/baseinfo';
    import {registerInfo} from '@/api/doctor/baseinfo';
    import {reSendVerifyCode,verifyCode,uploadRegisterFile}  from '@/api/upload';
    import {qCenters} from '@/api/healthcenter';

    export default {
        name: 'baseinfo',
        components: {
            vfrom,vfield,Button,Radio,RadioGroup,Popup ,Picker,vuploader,ActionSheet,vcheckbox
        },
        data(){
            return {
                user:{
                    name:'',
                    username:'',
                    // idCard:'',
                    phone:'',
                    sex:'男',
                    title:'',
                    remark:'',
                    fileid:'',
                    orgno:'',
                    // centerId:undefined,
                },
                time: '获取验证码',
                substatus:true,
                hasSendCode:false,
                code:'',
                isClick:true,
                showPicker:false,
                centerName:undefined,
                opts:[],
                centerMap:{},
                files:[],
                showFwxy:false,
                showYszc:false,
                isagree:false,
                agreeStatus:true,

            };
        },
        methods:{
            onConfirm(value){
                this.user.orgno = value.val;
                this.centerName=value.text;
                this.showPicker = false;
            },
            onSubmit(values) {
                if(values.centerName){
                    delete values.centerName;
                }
                console.log(values)
                console.log(this.user)
                registerInfo(this.user).then((response) => {
                    if(response.code==200 ){
                        // this.$router.push({path:'/doctorinfo',query:{}});
                        this.$toast.success('操作成功');
                    }else{
                        // this.$router.push({path:'/doctorinfo',query:{}});
                        this.$toast.fail('操作失败请稍候重试');
                    }
                });
            },
            // initBaseInfo(){
            //     getBaseInfo({}).then((response) => {
            //         if(response.code==200 ){
            //             this.user.nickName=response.data.nickName;
            //             this.user.idCard=response.data.idCard;
            //             this.user.phone=response.data.phone;
            //             this.user.gender=response.data.gender+'';
            //             this.user.orgno=response.data.centerId;
            //             if(this.user.centerId){
            //                 this.centerName=this.centerMap["id_"+this.user.centerId].text;
            //             }
            //         }
            //     });
            // },
            valiVerifyCode(){
                return new Promise((resolve) => {
                    if(!this.hasSendCode){
                        resolve(true);
                    }else{
                        this.Toast.loading('验证中...');
                        if(this.code){
                            verifyCode({phone:this.user.phone,code:this.code}).then((response) => {
                                this.Toast.clear();
                                this.hasSendCode=false;
                                if(200==response.code){
                                    this.substatus=false;
                                }
                                resolve(response.data);
                            })
                        }
                    }
                });
            },
            // 获取验证码
            async gainCode() {
                if (this.isClick) {
                    if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.user.phone)) {
                        await reSendVerifyCode({ phone: this.user.phone })  // 发送验证码接口
                        this.hasSendCode=true;
                        this.isClick = false
                        let s = 60
                        this.time = s + 's'
                        let interval = setInterval(() => {
                            s--
                            this.time = s + 's'
                            if (s < 0) {
                                this.time = '重新获取'
                                this.isClick = true
                                clearInterval(interval)
                            }
                        }, 1000)
                    } else {
                        this.Toast.fail('请输入正确的手机号码')
                    }
                }else{
                    this.Toast.fail('请稍候在获取')
                }
            },
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                console.log(file);
                file.status = 'uploading';
                file.message = '上传中...';
                console.info(file);
                let data={};
                data.content=file.content;
                data.note=file.file.name;
                data.totalBytes=file.file.size;
                data.fileType=file.file.type;
                data.orgno=this.user.no;
                uploadRegisterFile({},data).then((res)=>{
                    console.log(res)
                    if(200==res.code){
                        this.user.fileid=res.data;
                        file.status = 'success';
                        file.message = '上传成功';
                    }
                });
            },
            showFWXY(){
                this.showFwxy = true;
            },
            showYSZC(){
                this.showYszc = true;
            },
            changeAgree(ischecked){
                if(ischecked == true){
                    this.agreeStatus = false
                }else{
                    this.agreeStatus = true

                }
            }
        },
        created() {
            qCenters({}).then((response)=>{
                if(response.code==200 ){
                    this.opts=response.data;
                    // this.centerMap=this.builtMap(this.opts,'val',"id_");
                    // console.log(this.centerMap)
                }
                // this.initBaseInfo();
            });

        },
    }
</script>

<style scoped>


</style>
