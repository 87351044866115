
import request from '@/utils/request';


export function verifyCode(params){
      params.dataType='noKey';
      return request({
          url: '/verifyCode/verifyCode',
          method: 'post',
          params:params
        })
  
  }

  export function  reSendVerifyCode(params){
      params.dataType='noKey';
      return request({
        url: '/verifyCode/reSendVerifyCode',
        method: 'post',
        params:params
     })
  }


  export function  uploadFile(params,data){
      params.dataType='noKey';
      console.info(data);
      return request({
        url: '/fileUpload/uploadFile',
        method: 'post',
        params:params,
        data:data
     })
  }


  export function  uploadRegisterFile(params,data){
      params.dataType='noKey';
      console.info(data);
      return request({
        url: '/fileUpload/uploadRegisterFile',
        method: 'post',
        params:params,
        data:data
     })
  }

  export function  uploadRehabilitationUploadFiles(params,data){
      params.dataType='noKey';
      console.info(data);
      return request({
        url: '/fileUpload/uploadRehabilitationUploadFiles',
        method: 'post',
          headers:{
            "Content-Type":"multipart/form-data"
          },
        params:params,
        data:data
     })
  }

  export function  uploadWxUploadFiles(params,data){
      params.dataType='noKey';
      console.info(data);
      return request({
        url: '/fileUpload/uploadWxFile',
        method: 'post',
          headers:{
            "Content-Type":"multipart/form-data"
          },
        params:params,
        data:data
     })
  }

  
  export function  submitReport(params){
   params.dataType='noKey';
   return request({
     url: '/fileUpload/submitReport',
     method: 'post',
     params:params
  })
}


export function  delUploadFile(params){
  params.dataType='noKey';
  return request({
    url: '/fileUpload/delUploadFile',
    method: 'post',
    params:params
 })
}


export function  getUploadFiles(params) {
    params.dataType = 'noKey';
    return request({
        url: '/fileUpload/getWxUploadList',
        method: 'get',
        params: params
    })
}


export function  uploadImgOrVideo(params,data) {
    params.dataType = 'noKey';
    return request({
        url: '/weixin_pictures/createImageOrVideo.json',
        method: 'post',
        params: params,
        data:data
    })
}


export function  deleteVideo(params) {
    params.dataType = 'noKey';
    return request({
        url: '/fileUpload/deleteVideo',
        method: 'get',
        params: params,
    })
}

