// import {getToken} from '@/utils/auth';

import request from '@/utils/request';


export function registerInfo(params){
    // var token=getToken();
    // if(token){
    //     params.code=token;
    // }
    return request({
        url: '/wxmain/registerInfo',
        method: 'post',
        params:params
      })

}

export function getDoctors(params){
    // var token=getToken();
    // if(token){
    //     params.code=token;
    // }
    return request({
        url: '/baseinfo/my_doctors',
        method: 'get',
        params:params
      })

}
export function getChatDoctors(params){
    // var token=getToken();
    // if(token){
    //     params.code=token;
    // }
    return request({
        url: '/weixin_patients/chat_doctors.json',
        method: 'get',
        params:params
      })

}
export function updateChatDoctors(params){
    // var token=getToken();
    // if(token){
    //     params.code=token;
    // }
    return request({
        url: '/weixin_patients/chat_doctors_sync.json',
        method: 'get',
        params:params
      })

}
export function saveHistoryMessages(params,data){
    // var token=getToken();
    // if(token){
    //     params.code=token;
    // }
    return request({
        url: '/chat/save_message.json',
        method: 'post',
        params:params,
        data:data
      })

}


export function getMpUrlLink (params) {

  return request({
    url: '/baseinfo/getMpUrlLink',
    method: 'post',
    params: params
  })
}
