import request from '@/utils/request'



export function qCenters(params) {
  return request({
      url: '/baseinfo/qCenters',
      method: 'get',
      params:params
    })
  }
